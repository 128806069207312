import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import './register.scss';
import Recaptcha from 'components/recaptcha';
import config from 'components/config';
import isBrowser from 'components/lib/isbrowser'
import { Toaster, toast } from 'react-hot-toast'
import {
  register,
  getCurrentUser
} from 'components/lib/auth';
import { Input, Button, Checkbox } from 'components/common';
import rightArrow from 'assets/images/white-right-arrow.svg';
import Phone from '../../common/phone';
import { FormLabel } from '@material-ui/core';

const { recaptcha } = config;

const RegisterComponent = () => {
  const [values, setValues] = useState({
    jobTitle: '',
    email: ''
  })
  // const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [aggrementChecked, setAggrementChecked] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const [personInfo, setPersonInfo] = useState({ phone: '' });
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [phoneError, setPhoneError] = useState(true);
  const [error, setError] = useState('');
  const { t } = useTranslation()

  const onInputChange = useCallback(({ target }) => {
    const { id, value } = target;

    setValues({
      ...values,
      [id]: value
    });
  }, [values]);

  const onError = useCallback(({ message }) => {
    setError(message);
    setLoading(false);
  }, []);

  const onSuccess = useCallback((e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setValues({
      contactPerson: '',
      organization: '',
      email: '',
      website: ''
    })

    setPersonInfo({phone: ''})
    setSelectedCountry(null)
    
    toast.success(t('pages.profile.registrationSuccess'))
    setLoading(false);

    setTimeout(() =>
     isBrowser?  window.location.href ='/' : ''
    , 4000);
  }, []);

  const onRegister = useCallback((e) => {
    e.preventDefault();

    // const maybeValid = validate(values);

    // const tempValues = {
    //   ...values,
    //   userName: values.email,
    //   emails: [
    //     {
    //       value: values.email,
    //       primary: true
    //     }
    //   ]
    // }


    // if (maybeValid instanceof Error) {
    //   setError(maybeValid.message);
    //   return;
    // }


    // setError('');
    setLoading(true);
    const tempValues = {
      name: values?.contactPerson,
      organizationName: values?.organization,
      email: values?.email,
      phone: phoneNumber,
      jobTitle: values?.jobTitle,
      website: values?.website
    }
    register(tempValues)
      .then(onSuccess)
      .catch(onError);
  }, [onError, onSuccess, values]);

  const captchaCallback = useCallback((challenge) => {
    setValues({
      ...values,
      challenge
    });
  }, [values]);

  // const passwordStatus = useMemo(() => ({
  //   lengthRule: values.password.length >= 8,
  //   upperCaseRule: values.password && /[A-Z]/.test(values.password),
  //   numberRule: values.password && /\d/.test(values.password),
  //   charRule: values.password && /[!@#$~]/.test(values.password)
  // }), [values.password]);

  const isFormValid = useMemo(() => {
    return values.email &&
      values.organization &&
      // values.contactNumber &&
      values.contactPerson &&
      phoneError &&
      aggrementChecked;

  }, [values, aggrementChecked, phoneNumber])

  return (
    <form onSubmit={onRegister} className="register">
      <Toaster />
      <Input
        id="organization"
        name="organization"
        label={t('pages.profile.organizationName')}
        required
        value={values?.organization}
        onChange={onInputChange}
      />

      <Input
        id="contactPerson"
        name="name"
        required
        label={t('pages.profile.contactPerson')}
        value={values?.contactPerson}
        onChange={onInputChange}
      />

      <div className="register-field-double-item-wrapper">
       <div className='phone'>
       <Input
          id="email"
          name="email"
          label={t('pages.profile.email')}
          value={values?.email}
          required
          fullWidth
          onChange={onInputChange}
        />
       </div>

        {/* <Input
          id="contactNumber"
          name="tel"
          required
          label={t('pages.profile.contactPhone')}
          value={values?.contactNumber}
          onChange={onInputChange}
        /> */}
        <div className='contact'>
          <FormLabel>{t('pages.profile.contactPhone')} <span class="required">*</span></FormLabel>
          <Phone setPhoneNumber={setPhoneNumber} setPhoneError={setPhoneError} personInfo={personInfo} setPersonInfo={setPersonInfo} selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry}/>
        </div>
        
      </div>
      <Input
        id="website"
        name="website"
        label={t('common.website')}
        value={values?.website}
        onChange={onInputChange}
      />

      {/* <Input
        type={passwordVisible ? 'text' : 'password'}
        id="password"
        name="password"
        label="Password"
        autoComplete="off"
        value={values.password}
        required
        endAdornment={(
          <IconButton
            icon={passwordVisible ? 'fa fa-eye-slash' : 'fa fa-eye'}
            onClick={() => setPasswordVisible(!passwordVisible)}
          />
        )}
        onChange={onInputChange} />

      <div className="passwordRules">
        <Row>
          <Col xs={12} sm={12} md={6} lg={6}>
            <div className={"rule" + (passwordStatus.lengthRule ? ' valid' : '')}><i className="fa fa-check" /> At least 8 characters</div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <div className={"rule" + (passwordStatus.upperCaseRule ? ' valid' : '')}><i className="fa fa-check" /> At least 1 upper case letter</div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <div className={"rule" + (passwordStatus.numberRule ? ' valid' : '')}><i className="fa fa-check" /> At least 1 number</div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <div className={"rule" + (passwordStatus.charRule ? ' valid' : '')}><i className="fa fa-check" /> At least 1 special characters ! @ # $ ~</div>
          </Col>
        </Row>
      </div> */}

      <Checkbox
        wrapperClass="aggrement"
        checked={aggrementChecked}
        onChange={() => setAggrementChecked(!aggrementChecked)}
        label={
          <div className="aggrementLabel">
            {t('pages.profile.agreeTo')}{' '}
            <a target="_blank" href="/terms-and-conditions-of-use">
              {t('pages.profile.termsAndConditions')}
            </a>
          </div>
        }
      />

      {error ? <p className="error">{error}</p> : null}

      <ul className="actions captcha-actions">
        <li>
          <Button
            type="submit"
            className="special register-btn"
            disabled={loading || !isFormValid}
          >
            {loading ? <i className="fa fa-spin fa-circle-o-notch" /> : null}

            {getCurrentUser() ? t('common.resume') : t('common.signUp')}
            <figure>
              <img src={rightArrow} alt="right arrow" loading="lazy" />
            </figure>
          </Button>
        </li>
        {recaptcha.enabled ? (
          <li>
            <Recaptcha callback={captchaCallback} />
          </li>
        ) : null}
      </ul>
    </form>
  )
};

export default RegisterComponent;