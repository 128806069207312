import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next'
import { authUrl } from 'components/lib/auth';
import Register from 'components/form/register';
import Layout from 'components/layout/simple';
import './register.scss';

const authenticationUrl = authUrl();

const RegisterPage = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <div className="register-whole-wrapper">
      <Helmet title={t('pages.helmetTitle.register')} />

      <div className="register-wrapper">
        <div className="register-form-right-side-wrapper">
          <div className="register-form-content">
            <div className="register-form-heading">
              <h3>{`${t('common.signUp')} ${t('common.today')}`}!</h3>
              {/* <p>
                {t('pages.contact.alreadyHaveAccount')}?{' '}
                <a href={authenticationUrl} className="navbar-item">
                  <span>{t('common.signIn')}</span>
                </a>
              </p> */}
            </div>
            {/* <div className="register-or-wrapper">
                <p>OR</p>
                <div>
                  <hr />
                </div>
              </div> */}
          </div>
          <div className="register-form-wrapper">
            <Register />
          </div>
        </div>
      </div>
    </div>
    </Layout>
  )
}

export default RegisterPage;