import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactCountryFlag from "react-country-flag";
import phone from "phone";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import TextField from "./TextField";
import {
  TextField as MUITextField,
  withStyles,
  Box,
  InputAdornment,
  styled,
  Typography
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { countries } from "./countries";
import './phone.scss'

const StyledPhoneCode = styled(Typography)({
  fontSize: 13
});
const StyledAutoComplete = withStyles({
  root: {
   
    "& .MuiInputBase-root": {
      background: "#ffff",
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0
    }
  },
  paper: {
    width: 260
  }
})(Autocomplete);
const StyledTextField = withStyles({
  root: {
    "& .MuiInputBase-root": {
      height: 42,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0
    }
  }
})(TextField);

const StyledCountrySelectedTextField = withStyles({
  root: {
    "& .MuiInputBase-root": {
      height: "100%",
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      paddingRight: 0
    },
    "& .MuiInputBase-root > .MuiInputBase-input": {
      height: 0,
      width: 0
    }
  }
})(MUITextField);

function PhoneNumber(props) {
  const { personInfo, setPersonInfo, setPhoneNumber, setPhoneError, selectedCountry, setSelectedCountry } = props;
  const TagName = selectedCountry
    ? StyledCountrySelectedTextField
    : MUITextField;

    useEffect(()=>{
      let b = `+${selectedCountry?.phone}${personInfo?.phone}`;
      setPhoneNumber(`+${selectedCountry?.phone}${personInfo?.phone}`);
      let a = phone(b, {strictDetection: true});
      setPhoneError(a?.isValid);
    },[personInfo])
  return (
    <Box display="flex" className="phone-box">
      <StyledAutoComplete
        autoHighlight
        openOnFocus
        closeIcon={false}
        size="small"
        multiple
        options={countries}
        value={countries.filter(
          (c) => selectedCountry && c.label == selectedCountry.label
        )}
        onChange={(e, allValues, type, value) =>
          setSelectedCountry(value?.option)
        }
        filterOptions={(options, { inputValue, selected }) => {
          if (inputValue != "") {
            options = options.filter((option) =>
              `${option?.label}${option?.phone}`
                .toLowerCase()
                .includes(inputValue.toLowerCase())
            );
            return options;
          } else return options;
        }}
        getOptionLabel={(option) => `${option.label} (${"+" + option.phone})`}
        renderOption={(option, { selected, inputValue }) => {
          const matches = match(
            `${option.label} (${"+" + option.phone})`,
            inputValue
          );
          const parts = parse(
            `${option.label} (${"+" + option.phone})`,
            matches
          );

          return (
            <div>
              <ReactCountryFlag
                countryCode={option.code}
                svg
                style={{ marginRight: 16 }}
              />
              {parts.map((part, index) => {
                return (
                  <span
                    key={index}
                    style={{ ...(part.highlight && { color: "#78909c" }) }}
                  >
                    {part.text}
                  </span>
                );
              })}
            </div>
          );
        }}
        renderTags={() => (
          <ReactCountryFlag
            style={{ height: 25, width: 25 }}
            countryCode={selectedCountry.code}
            svg
          />
        )}
        renderInput={(params) => (
          <TagName
            {...params}
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              style: { ...(selectedCountry && { padding: 0 }) }
            }}
          />
        )}
      />
      <StyledTextField
        type="tel"
        disabled={selectedCountry ? false : true}
        value={personInfo.phone}
        error={
          personInfo.phone && selectedCountry
            ? !phone(`+${selectedCountry.phone}${personInfo.phone}`, {strictDetection: true})?.isValid
              ? true
              : false
            : false
        }
        onChange={(e) =>
          setPersonInfo({ ...personInfo, phone: e.target.value })
        }
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <StyledPhoneCode>
                {selectedCountry ? "+" + selectedCountry.phone : ""}
              </StyledPhoneCode>
            </InputAdornment>
          )
        }}
      />
    </Box>
  );
}
PhoneNumber.propTypes = {
  personInfo: PropTypes.object.isRequired,
  setPersonInfo: PropTypes.func.isRequired
};
export default PhoneNumber;
