import React, { useEffect, useState } from "react";
import PhoneNumber from "./phoneNumber";
import './phone.scss'

function Phone(props) {
  const { setPhoneNumber, setPhoneError, personInfo, setPersonInfo, selectedCountry, setSelectedCountry } = props;

  return (
    <>
      <PhoneNumber personInfo={personInfo} setPersonInfo={setPersonInfo} setPhoneNumber={setPhoneNumber} setPhoneError={setPhoneError} selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry}/>
    </>
  );
}
Phone.propTypes = {};
export default Phone;
