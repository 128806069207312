import React from 'react';
import Recaptcha from 'react-google-recaptcha';

import config from 'components/config';

const { sitekey } = config.recaptcha;

export default ({
  onLoad,
  callback
}) =>
  <Recaptcha
    ref={(ref) =>
      ref &&
      onLoad &&
      onLoad(ref)
    }
    render="explicit"
    sitekey={sitekey}
    onChange={callback} />
